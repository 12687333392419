/********************************************************************
 * Do not update this file manually!
 * Use 'yarn sync-flags' inside 'editor-plugin-ai' folder to update it
 *********************************************************************/

export const EDITOR_AI_PLATFORM_FEATURE_FLAGS = {
	'platform.editor.ai-assistance-service-free-generate': {
		type: 'boolean',
	},
	'platform.editor.ai-send-full-content_r1qpp': {
		type: 'boolean',
	},
	'platform.editor.ai-remove-beta-lozenge_s5yrq': {
		type: 'boolean',
		referenceOnly: true,
	},
	'platform.editor.ai-save-response-history-in-state_dw857': {
		type: 'boolean',
	},
	'platform.editor.ai-dynamic-show-hide-quick-insert-items_4ie8s': {
		type: 'boolean',
	},
	'platform.editor.ai-tag-synthetic-and-internal-users_eb7tz': {
		type: 'boolean',
	},
	'platform.editor.ai-enable-multi-lang-translation-prompts': {
		type: 'boolean',
	},
	'platform.editor.ai-enable-multi-lang-translations-in-quick-insert': {
		type: 'boolean',
	},
	'platform.editor.ai-interrogate-with-convo-ai': {
		type: 'boolean',
	},
	'platform.editor.ai-assistance-service-summarise-writing': {
		type: 'boolean',
	},
	'platform.editor.ai-assistance-service-improve-writing': {
		type: 'boolean',
	},
	'platform.editor.ai-assistance-service-fix-spelling-grammar': {
		type: 'boolean',
	},
	'platform.editor.ai-assistance-service-suggest-title': {
		type: 'boolean',
	},
	'platform.editor.ai-assistance-service-make-shorter': {
		type: 'boolean',
	},
	'platform.editor.ai-assistance-service-find-action-items': {
		type: 'boolean',
	},
	'platform.editor.ai-assistance-service-brainstorm': {
		type: 'boolean',
	},
	'platform.editor.ai-assistance-service-change-tone': {
		type: 'boolean',
	},
	'platform.editor.ai-assistance-service-translate-to': {
		type: 'boolean',
	},
	'platform.editor.ai.enable-ai-summary-blocks_r040m': {
		type: 'boolean',
	},
	'platform.editor.ai.enable-ai-action-items-block_vctyf': {
		type: 'boolean',
	},
	'platform.reformat-issue-with-template-in-jira_jc0pb': {
		type: 'boolean',
	},
	'platform.editor.ai-multi-lang-translations-popular-sort-order': {
		type: 'boolean',
	},
	'platform.editor.ai-translate-in-floating-toolbar_7dqkh': {
		type: 'boolean',
	},
	'platform.editor.ai-rovo-agents': {
		type: 'boolean',
	},
	'platform.ai.mate.actions_enabled_rleev': {
		type: 'boolean',
	},
	platform_editor_ai_proactive_spelling_and_grammar: {
		type: 'boolean',
	},
	'rovo-chat-browse-agents': {
		type: 'boolean',
	},
	'platform.editor.ai-assistance-service-pr-description': {
		type: 'boolean',
	},
	platform_editor_ai_sg_better_traffic_projection: {
		type: 'boolean',
	},
	platform_editor_ai_command_palate_improvement_fg: {
		type: 'boolean',
	},
};
