/**
 /** @jsx jsx */
/** @jsx jsx */
import React, { useState, type CSSProperties } from 'react';
import { css, jsx } from '@compiled/react';
import Modal, { ModalBody, ModalTransition } from '@atlaskit/modal-dialog';
import { token } from '@atlaskit/tokens';
import { N0 } from '@atlaskit/theme/colors';
import { type CoreData } from '@atlassian/forge-ui-types';

import { Iframe, getModalDimensions, type ModalSizesType } from '../../custom-ui';
import { type ForgeUIRendererProps } from './main';

interface ModalState {
	resource?: string | null;
	onClose?: (payload?: any) => void;
	size?: ModalSizesType;
	context?: any;
	closeOnEscape?: boolean;
	closeOnOverlayClick?: boolean;
	requiresUserConsent?: boolean;
}

export type CustomUIRendererProps = Omit<ForgeUIRendererProps, 'cloudId' | 'workspaceId'> & {
	coreData: CoreData;
	getThreeLOPrompt: () => { ThreeLOPrompt: (props: any) => JSX.Element };
	modalStyles?: CSSProperties;
};

type ModalWidths = 'small' | 'medium' | 'large' | 'xlarge' | 'max';
type ModalWrapperProps = { children: JSX.Element; size: ModalSizesType };

export const getAtlasKitModalWidth = (modalSize: ModalWidths) => {
	if (modalSize === 'xlarge') {
		return 'x-large';
	}
	if (modalSize === 'max') {
		return '100%';
	}
	return modalSize;
};

const smallModalStyles = css({
	height: '20vh',
	minHeight: '320px',
	background: token('elevation.surface.overlay', N0),
	borderRadius: token('border.radius', '3px'),
});

const mediumModalStyles = css({
	height: '40vh',
	minHeight: '520px',
	background: token('elevation.surface.overlay', N0),
	borderRadius: token('border.radius', '3px'),
});

const largeModalStyles = css({
	height: '70vh',
	minHeight: '720px',
	background: token('elevation.surface.overlay', N0),
	borderRadius: token('border.radius', '3px'),
});

const xlargeModalStyles = css({
	height: '90vh',
	background: token('elevation.surface.overlay', N0),
	borderRadius: token('border.radius', '3px'),
});

const maxModalStyles = css({
	height: '100%',
	background: token('elevation.surface.overlay', N0),
	borderRadius: token('border.radius', '3px'),
});

const ModalWrapper = ({ children, size }: ModalWrapperProps) => {
	switch (size) {
		case 'small':
			return <div css={smallModalStyles}>{children}</div>;
		case 'medium':
			return <div css={mediumModalStyles}>{children}</div>;
		case 'large':
			return <div css={largeModalStyles}>{children}</div>;
		case 'xlarge':
			return <div css={xlargeModalStyles}>{children}</div>;
		case 'max':
			return <div css={maxModalStyles}>{children}</div>;
		default:
			return <ModalBody>{children}</ModalBody>;
	}
};

// TODO: need to handle modal extension points, e.g. confluence:contentAction
// TODO: add support for Confluence dialogs
// TODO: add support for Confluence custom closeOnEscape logic in ModalIframeRenderer
export const CustomUIRenderer = (props: CustomUIRendererProps) => {
	const { extension, extensionData, bridge, height } = props;

	const [modalState, setModalState] = useState<undefined | ModalState>(undefined);

	const iframeProps = {
		accountId: props.accountId,
		apolloClient: props.client,
		contextIds: props.contextIds,
		components: props.getThreeLOPrompt,
		coreData: props.coreData,
		customBridgeMethods: props.customBridgeMethods,
		extension: props.extension,
		extensionData: props.extensionData,
		getContextToken: props.getContextToken,
		locale: props.locale,
		timezone: props.timezone,
	};

	const modalSize = modalState?.size ?? extension.properties.viewportSize ?? 'medium';
	const { height: modalHeight } = getModalDimensions(modalSize);
	const modalWidth = getAtlasKitModalWidth(modalSize);

	const isResizable = !extension.properties.viewportSize;

	return (
		<React.Fragment>
			<Iframe
				{...iframeProps}
				bridge={{
					...bridge,
					openModal: (opts, consentState) => {
						setModalState({
							...opts.data,
							requiresUserConsent: Boolean(consentState?.requiresUserConsent),
						});
						return true;
					},
				}}
				isResizable={isResizable}
				height={!isResizable ? height : undefined}
			/>
			<ModalTransition>
				{modalState && (
					<Modal
						width={modalWidth}
						height={modalSize === 'max' ? modalHeight : undefined}
						onClose={() => {
							if (modalState?.onClose) {
								modalState.onClose();
							}
							setModalState(undefined);
						}}
						onCloseComplete={() => setModalState(undefined)}
						testId="custom-ui-modal-dialog"
						shouldCloseOnEscapePress={modalState.closeOnEscape}
						shouldCloseOnOverlayClick={modalState.closeOnOverlayClick}
					>
						<ModalWrapper size={modalSize}>
							<Iframe
								{...iframeProps}
								isInModal={true}
								extension={{
									...extension,
									type: 'dynamic-modal',
									properties: {
										...extension.properties,
										resource: modalState.resource ?? extension.properties.resource,
									},
									requiresUserConsent: modalState.requiresUserConsent,
								}}
								extensionData={{
									...extensionData,
									...(modalState.context ? { modal: modalState.context } : {}),
								}}
								bridge={{
									...bridge,
									close: (payload?: { data: any }) => {
										if (modalState && modalState.onClose) {
											modalState.onClose(payload?.data);
											setModalState(undefined);
										}
									},
								}}
								height="100%"
								width="100%"
							/>
						</ModalWrapper>
					</Modal>
				)}
			</ModalTransition>
		</React.Fragment>
	);
};
