/** @jsx jsx */
import React, {
	Suspense,
	useContext,
	useEffect,
	useMemo,
	useState,
	useRef,
	forwardRef,
	Fragment,
} from 'react';
import { css, jsx } from '@compiled/react';
import { Box, xcss } from '@atlaskit/primitives';
import { N30A, N60A } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import type { ForgeContextToken } from '@atlassian/forge-ui-types';
import { useMergeRefs } from 'use-callback-ref';

import { EnvironmentContext } from '../../context';
import { useInvokeExtension } from '../../web-client';
import { ThreeLOPrompt } from '../../components';
import {
	useBridge,
	useProductFetchClient,
	useNavigation,
	createBridgeApi,
	isBinaryContentType,
} from '../bridge';
import { useTracingContext } from '../../error-reporting';
import { getBrowserLocale, getBrowserTimezone, getResolverContext, getViewContext } from './utils';
import type { InnerIframeProps, ComponentMap, HTMLCustomIFrameElement } from './types';
import { isAppConsentedLocally, setAppConsentLocal } from './localAppConsent';
import { getLoadingComponent } from './utils/getLoadingComponent';

type InvokeRetry = () => Promise<void>;

// TODO: no styles defined for resizeable modals yet.
// Only Jira enables resizing on modals at the moment.
const modalIframeStyles = css({
	boxShadow: token(
		'elevation.shadow.overlay',
		`0 0 0 1px ${N30A}, 0 2px 1px ${N30A}, 0 0 20px -6px ${N60A}`,
	),
	borderRadius: token('border.radius', '3px'),
	height: '100%',
});

const iframeStyles = css({
	border: 'none',
	display: 'initial',
	width: '100%',
});

const hiddenIframeStyles = css({
	display: 'none',
});

const nonResizeableIframeStyles = css({
	height: '100%',
});

const defaultComponents: ComponentMap = {
	ThreeLOPrompt: (props) => <ThreeLOPrompt {...props} />,
};

/**
 * Hook for determining default IFrame React ref, using parent ref when provided
 *
 * @param parentIframeRef parent IFrame React ref
 * @returns default IFrame React ref
 */
function useDefaultIframeRef(parentIframeRef: React.Ref<HTMLCustomIFrameElement>) {
	const localIframeRef = useRef<HTMLCustomIFrameElement>(null);
	const mergedRef = useMergeRefs([parentIframeRef, localIframeRef]);
	return mergedRef;
}

export const InnerIframe = forwardRef<HTMLCustomIFrameElement, InnerIframeProps>(
	(
		{
			accountId,
			extension,
			contextIds,
			apolloClient,
			components,
			coreData,
			extensionData,
			loadingComponent,
			onLoad,
			height = '100%',
			width = '100%',
			src,
			bridge,
			customBridgeMethods,
			isResizable,
			getContextToken,
			timezone,
			locale,
			setIsThreeLOPromptVisible,
			extensionPayload,
			isHidden,
			theme,
			surfaceColor,
			appId,
			isInModal,
		},
		parentIframeRef,
	) => {
		const { ThreeLOPrompt } = components ? components(defaultComponents) : defaultComponents;

		const appIsAuthenticated = isAppConsentedLocally(appId, accountId);
		const { consentUrl, currentUserConsent, requiresUserConsent } = extension;
		const [userConsentRequired, setUserConsentRequired] = useState(
			consentUrl && !currentUserConsent && requiresUserConsent && !appIsAuthenticated,
		);

		const productContext = useMemo(() => {
			const resolverContext = getResolverContext(coreData, extensionData);
			const viewContext = getViewContext(
				resolverContext,
				accountId,
				extension,
				timezone ?? getBrowserTimezone(),
				locale ?? getBrowserLocale(),
				extensionPayload,
				theme,
				surfaceColor,
			);
			return { resolverContext, viewContext };
		}, [
			coreData,
			extensionData,
			accountId,
			extension,
			timezone,
			locale,
			extensionPayload,
			theme,
			surfaceColor,
		]);

		const tracing = useTracingContext();

		const contextTokenRef = useRef<ForgeContextToken | undefined>(undefined);

		const [threeLOInfo, setThreeLOInfo] = useState<{
			authUrl: string;
			onSuccess: () => Promise<void>;
			onReject: () => void;
		} | null>(null);

		const invokeRetriesRef = useRef<Array<InvokeRetry>>([]);

		const iframeRef = useDefaultIframeRef(parentIframeRef);

		useEffect(() => {
			const clearThreeLOInfo = async () => setThreeLOInfo(null);

			if (userConsentRequired) {
				tracing?.recordConsentFlow('consent flow - start');

				setThreeLOInfo({
					authUrl: consentUrl!,
					onSuccess: async () => {
						tracing?.recordConsentFlow('consent flow - end', 'success');
						setUserConsentRequired(false);
						setIsThreeLOPromptVisible(false);
						setAppConsentLocal(appId, accountId);
						return clearThreeLOInfo();
					},
					onReject: () => {
						tracing?.recordConsentFlow('consent flow - end', 'rejected');
						return clearThreeLOInfo();
					},
				});
				setIsThreeLOPromptVisible(true);

				if (onLoad) {
					onLoad();
				}
			}
		}, [
			onLoad,
			setIsThreeLOPromptVisible,
			tracing,
			appId,
			accountId,
			consentUrl,
			userConsentRequired,
		]);

		const navigation = useNavigation({ extension, push: bridge?.spaPush });

		const invoker = useInvokeExtension(contextIds, extension.id, {
			client: apolloClient,
		});

		const environment = useContext(EnvironmentContext);
		const { cloudId, workspaceId } = coreData;
		const productFetchClient = useProductFetchClient({
			environment,
			contextIds,
			cloudId,
			workspaceId,
			extensionId: extension.id,
			options: { client: apolloClient },
			renderThreeLOPrompt: (retry: () => Promise<void>) => {
				return new Promise((resolve, reject) => {
					const authUrl = threeLOInfo?.authUrl || consentUrl;
					if (authUrl) {
						invokeRetriesRef.current.push(retry);

						tracing?.recordConsentFlow('consent flow - start');

						setThreeLOInfo({
							authUrl,
							onSuccess: () => {
								tracing?.recordConsentFlow('consent flow - end', 'success');
								resolve();
								setThreeLOInfo(null);
								setIsThreeLOPromptVisible(false);
								return Promise.all(invokeRetriesRef.current.map((retry) => retry())).then(() => {
									invokeRetriesRef.current = [];
								});
							},
							onReject: () => {
								tracing?.recordConsentFlow('consent flow - end', 'rejected');
								return reject();
							},
						});
						setIsThreeLOPromptVisible(true);
					}
				});
			},
		});

		const {
			loading,
			iframeProps: { onLoad: onLoadIframeFromProps },
		} = useBridge({
			theme,
			surfaceColor,
			origin: new URL(src).origin,
			api: {
				...createBridgeApi(bridge, {
					userConsentRequired: !!userConsentRequired,
				}),
				onInvoke: async (payload) => {
					const newContextToken = getContextToken
						? await getContextToken()
						: contextTokenRef.current?.jwt;

					tracing?.recordGqlCall('invokeExtension - start');

					const { data } = await invoker({
						call: payload,
						context: {
							...productContext.resolverContext,
						},
						extensionPayload,
						contextToken: newContextToken,
					});
					const resp = data?.invokeExtension;
					if (!resp?.success) {
						const error = resp?.errors?.[0];
						if (error?.extensions.errorType === 'USER_CONSENT_REQUIRED') {
							return {
								type: '3lo',
								authUrl: error?.extensions.fields?.authInfoUrl as string,
							};
						}
						return {
							type: 'err',
							message: error?.message ?? 'An error occurred while trying to invoke.',
						};
					}

					tracing?.recordGqlCall('invokeExtension - end');

					contextTokenRef.current = resp?.contextToken;
					return {
						type: 'ok',
						response: resp?.response?.body,
					};
				},
				onNavigate: navigation.onNavigate,
				onThreeLO: async (authUrl, retry: InvokeRetry) => {
					return new Promise((resolve, reject) => {
						invokeRetriesRef.current.push(retry);

						tracing?.recordConsentFlow('consent flow - start');

						setThreeLOInfo({
							authUrl,
							onSuccess: () => {
								tracing?.recordConsentFlow('consent flow - end', 'success');
								resolve();
								setThreeLOInfo(null);
								setIsThreeLOPromptVisible(false);
								return Promise.all(invokeRetriesRef.current.map((retry) => retry())).then(() => {
									invokeRetriesRef.current = [];
								});
							},
							onReject: () => {
								tracing?.recordConsentFlow('consent flow - end', 'rejected');
								return reject();
							},
						});
						setIsThreeLOPromptVisible(true);
					});
				},
				getContext: async () => ({
					...productContext.viewContext,
				}),
				fetchProduct: async ({ restPath, product, fetchRequestInit, isMultipartFormData }) => {
					const response = await productFetchClient(
						restPath,
						product,
						fetchRequestInit,
						isMultipartFormData,
					);

					const { status, statusText } = response;
					const headers = Object.fromEntries((response.headers as any).entries());
					const isAttachment = isBinaryContentType(response.headers.get('content-type'));
					const body = response.body ? await response.text() : undefined;

					return { headers, status, statusText, body, isAttachment };
				},
				getFrameId: () => extension.properties.frameId ?? null,
			},
			customBridgeMethods,
			onLoad,
			isResizable,
			height,
			environment,
			extension,
			iframeRef,
		});

		return (
			<Box
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766
				xcss={xcss({
					position: 'relative',
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
					height: isResizable || threeLOInfo ? undefined : height,
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
					width,
				})}
			>
				{navigation.getModalJsx()}
				{(loading || threeLOInfo) && (
					<Suspense fallback={null}>
						{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766 */}
						<Box xcss={xcss({ width: '100%', height: '100%' })}>
							{loading && !threeLOInfo && !isHidden && getLoadingComponent(loadingComponent)}
							{threeLOInfo &&
								ThreeLOPrompt({
									authUrl: threeLOInfo.authUrl,
									onSuccess: threeLOInfo.onSuccess,
									metadata: {
										moduleType: extension.type,
									},
								})}
						</Box>
					</Suspense>
				)}
				{!userConsentRequired && (
					<Fragment>
						<iframe
							data-testid="hosted-resources-iframe"
							data-forge-iframe
							css={[
								iframeStyles,
								(loading || threeLOInfo || isHidden) && hiddenIframeStyles,
								isInModal && modalIframeStyles,
								!isResizable && nonResizeableIframeStyles,
							]}
							sandbox="allow-downloads allow-forms allow-modals allow-pointer-lock allow-same-origin allow-scripts"
							allow="camera; clipboard-write; display-capture; fullscreen; microphone"
							src={src}
							ref={iframeRef}
							onLoad={onLoadIframeFromProps}
						/>
					</Fragment>
				)}
			</Box>
		);
	},
);
