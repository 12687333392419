import React, { type CSSProperties, useCallback, useEffect, useMemo } from 'react';
import type ApolloClient from 'apollo-client';

import { type MentionProvider } from '@atlaskit/mention';
import { type ForgeDoc, type ExtensionData } from '@atlassian/forge-ui-types';

import { CustomUIRenderer } from './CustomUIRenderer';
import { UIKitRenderer } from './UIKitRenderer';

import { type ComponentMap, type ModalExtension } from '..';
import { type ProductKey } from '../../components/UIKit1/userPicker';
import { type BridgeConfig } from '../../custom-ui/bridge/types';
import { type Extension } from '../../web-client';
import { makeModalThreeLOPromptForCustomUI, makeThreeLOPromptForCustomUI } from '../../components';
import { ForgeUIExtensionAnalyticsContext } from '../../analytics';

interface CommonProps {
	accountId: string | undefined;
	client: ApolloClient<any>;
	contextIds: Array<string>;
	extension: Extension;
	extensionData: ExtensionData;
	product: ProductKey | 'sandbox';
	bridge?: BridgeConfig;
	customBridgeMethods?: Record<string, (...args: any[]) => any>;
	components?: ComponentMap;
	consentMessage?: string;
	entryPoint?: string;
	getContextToken?: () => Promise<string>;
	height?: string;
	locale?: string;
	localId?: string;
	mentionProvider?: Promise<MentionProvider>;
	modalExtension?: ModalExtension;
	modalStyles?: CSSProperties;
	onConsentSuccess?: () => void;
	onForgeDocUpdated?: (forgeDoc: ForgeDoc) => void;
	onInitialRender?: () => void;
	timezone?: string;
	onConsentModalClose?: () => void;
}

export type ForgeUIRendererProps = CommonProps & ({ cloudId: string } | { workspaceId: string });

export const ForgeUIRenderer = (props: ForgeUIRendererProps) => {
	const {
		extension,
		onConsentSuccess,
		onConsentModalClose,
		consentMessage,
		modalExtension,
		onInitialRender,
		localId,
	} = props;

	useEffect(() => {
		onInitialRender?.();
	}, [onInitialRender]);

	let cloudId: string | undefined;
	let workspaceId: string | undefined;

	if ('cloudId' in props) {
		cloudId = props.cloudId;
	} else if ('workspaceId' in props) {
		workspaceId = props.workspaceId;
	}

	const { id: extensionId, environmentId, environmentType } = extension;
	const coreData = useMemo(
		() => ({
			workspaceId,
			cloudId,
			localId: localId || extensionId,
			environmentId,
			environmentType,
		}),
		[cloudId, environmentId, environmentType, extensionId, localId, workspaceId],
	);

	const getThreeLOPrompt = useCallback(
		() => ({
			ThreeLOPrompt: modalExtension
				? makeModalThreeLOPromptForCustomUI({
						appName: extension.properties.title,
						isBlanketHidden: true,
						onClose: onConsentModalClose,
					})
				: makeThreeLOPromptForCustomUI({
						appName: extension.properties.title,
						onSuccess: onConsentSuccess,
						message: consentMessage,
					}),
		}),
		[
			extension.properties.title,
			consentMessage,
			modalExtension,
			onConsentSuccess,
			onConsentModalClose,
		],
	);

	const isCustomUI = extension.properties.resource && extension.properties.render !== 'native';
	return (
		<ForgeUIExtensionAnalyticsContext extensionId={extension.id} localId={localId || extension.id}>
			{isCustomUI ? (
				<CustomUIRenderer {...props} coreData={coreData} getThreeLOPrompt={getThreeLOPrompt} />
			) : (
				<UIKitRenderer {...props} coreData={coreData} getThreeLOPrompt={getThreeLOPrompt} />
			)}
		</ForgeUIExtensionAnalyticsContext>
	);
};
